import React from 'react'
import { useTranslation } from 'react-i18next'

import { IGetIpInformationQuery } from '../../../../Lib/graphql'
import Styles from '../../styles.module.scss'

export const WhoisResponse = ({ data }: { data?: IGetIpInformationQuery }): JSX.Element | null => {
  const { t } = useTranslation()
  if (!data?.ip_information_by_pk) return null

  const renderRow = (label: string, value: any): JSX.Element => {
    return (
      <tr>
        <td>{label}</td>
        <td>{value?.toString()}</td>
      </tr>
    )
  }

  return (
    <table className={Styles.table}>
      <caption>{t('Whois Information')}</caption>
      <tbody>
        {renderRow('Name', data.ip_information_by_pk.whois_name || 'unknown')}
        {renderRow('Organization', data.ip_information_by_pk.whois_organization || 'unknown')}
        {renderRow('Contact', data.ip_information_by_pk.whois_contact_email || 'unknown')}
      </tbody>
    </table>
  )
}
