import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import fetch from 'cross-fetch'

export const apolloClient = (jwt: string): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: 'https://cerberus.hasura.app/v1/graphql',
    fetch,
  })

  const wsLink = new WebSocketLink({
    uri: 'wss://cerberus.hasura.app/v1/graphql',
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          authorization: `Bearer ${jwt}`,
          'X-smeevil': 'was-here',
        },
      },
    },
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwt}`,
        'X-smeevil-2': 'was-here',
      },
    }
  })

  console.log({ authLink })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLink)
  )

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  })
}
