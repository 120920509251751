/* eslint-disable no-undef */
// Above lint disable is to make storybook work, for some reason it cannot
// resolve the google global even though it's defined as types in our tsconfig
// If you know how to solve this, please do :) /smeevil
import { useCallback, useEffect, useRef, useState } from 'react'

import { injectGoogleMapsJs } from './helpers'
import { IMarkerPosition, IOptions, IPath, IReturnValue, TLatitude, TLongitude } from './interfaces'

export const useGoogleMaps = ({
  mapContainerRef,
  latitude = 52.152609,
  longitude = 5.38346,
  setInitialPositionMarker = true,
  setLocationBounds = false,
  zoomLevel = 14,
  onClick,
  mapType = 'DRIVING',
}: IOptions): IReturnValue => {
  const mapInstance = useRef<google.maps.Map<HTMLDivElement>>()
  const [loaded, setLoaded] = useState<boolean>(false)
  const markers = useRef<google.maps.Marker[]>([])
  const flightPath = useRef<google.maps.Polyline>()

  const setZoomLevel = (level: number): void => {
    const map = mapInstance.current
    if (!map) return
    map.setZoom(level)
  }

  const setMapOnAll = (map: google.maps.Map | null): void => {
    markers.current.forEach((marker) => {
      marker.setMap(map)
    })
  }

  const setBoundsForMarkerLocations = (map: google.maps.Map): void => {
    const bounds = new google.maps.LatLngBounds()
    markers.current.forEach((marker) => {
      const location = marker.getPosition()
      if (location) bounds.extend(location)
    })
    map.fitBounds(bounds)
  }

  const setPath = (path: IPath[]): void => {
    const convertedPath = path.map((coordinates) => {
      return { lat: coordinates.latitude, lng: coordinates.longitude }
    })

    if (!mapInstance.current) return
    if (!flightPath.current) {
      flightPath.current = new google.maps.Polyline({
        path: convertedPath,
        geodesic: true,
        strokeColor: '#3c9cff',
        strokeOpacity: 1.0,
        strokeWeight: 4,
      })
    } else flightPath.current.setPath(convertedPath)

    flightPath.current.setMap(mapInstance.current)

    if (mapType === 'BICYCLING') new google.maps.BicyclingLayer().setMap(mapInstance.current)
    if (mapType === 'TRANSIT') new google.maps.TransitLayer().setMap(mapInstance.current)
  }

  const directionService = (origin: IPath, destination: IPath): void => {
    if (!mapInstance.current) return
    new google.maps.DirectionsService().route(
      {
        origin: new google.maps.LatLng(origin.latitude, origin.longitude),
        destination: new google.maps.LatLng(destination.latitude, destination.longitude),
        travelMode: google.maps.TravelMode[mapType],
      },
      (result, _status) => {
        const calcluatedPath = result.routes[0].overview_path.map((point) => {
          return {
            latitude: point.lat(),
            longitude: point.lng(),
          }
        })

        setPath(calcluatedPath)
      }
    )
  }

  const clearMarkers = useCallback((): void => {
    setMapOnAll(null)
    markers.current = []
  }, [])

  const setMarker = useCallback(
    (lat: TLatitude, long: TLongitude): void => {
      const map = mapInstance.current

      if (!map) return

      const pinImage = markers.current.length ? 'spotlight-waypoint-b.png' : 'spotlight-waypoint-a.png'
      const location = new google.maps.LatLng(lat, long)
      const marker = new google.maps.Marker({
        position: location,
        map,
      })
      marker.setIcon(
        `https://mts.googleapis.com/vt/icon/name=icons/spotlight/${pinImage}&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1`
      )
      markers.current.push(marker)
      setMapOnAll(map)

      setLocationBounds ? setBoundsForMarkerLocations(map) : map.setCenter(location)
    },
    [setLocationBounds]
  )

  const replaceMarkers = (newMarkers: IMarkerPosition[]): void => {
    clearMarkers()
    newMarkers.forEach((item) => setMarker(item.lat, item.long))
  }

  const googleMapsLoaded = useCallback(() => {
    if (!mapContainerRef.current) return
    mapInstance.current = new google.maps.Map(mapContainerRef.current, {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      scrollwheel: false,
    })

    if (onClick) {
      mapInstance.current.setOptions({ draggableCursor: 'crosshair' })
      mapInstance.current.addListener('click', (event: any) => {
        const newLat = event.latLng.lat()
        const newLng = event.latLng.lng()
        onClick(newLat, newLng)
        clearMarkers()
        setMarker(newLat, newLng)
      })
    }

    if (setInitialPositionMarker) setMarker(latitude, longitude)
    setLoaded(true)
  }, [mapContainerRef, onClick, setInitialPositionMarker, setMarker, latitude, longitude, clearMarkers])

  useEffect(() => {
    if (!mapContainerRef.current) return

    injectGoogleMapsJs(googleMapsLoaded)
  }, [mapContainerRef, googleMapsLoaded])

  setZoomLevel(zoomLevel)

  return {
    mapInstance: mapInstance.current,
    setPath,
    setMarker,
    setZoomLevel,
    mapLoaded: loaded,
    replaceMarkers,
    directionService,
    clearMarkers,
  }
}
