import dayjs from 'dayjs'
import React from 'react'
import { IoMdFlag } from 'react-icons/io'

import { Icon } from '../Icon'
import { IRowProps } from './interfaces'
import Styles from './styles.module.scss'

export const Row: React.FC<IRowProps> = ({ data, flag, onClick }): JSX.Element => {
  const showFlag = data.notifications.length > 0
  const flagClasses = [Styles.flag]

  return (
    <tr key={data.id} onClick={onClick}>
      <td className={flagClasses.join(' ')}>
        {showFlag && <IoMdFlag />}
        {(data.ipInfo?.is_malicious || data.ipInfo?.is_abusive) && <Icon name="biohazard" fill="green" />}
      </td>
      <td>{dayjs(data.reported_at).format('DD-MM-YY HH:mm:ss')}</td>
      <td>{data.client_ip}</td>
      <td>{data.environment}</td>
      <td>{data.event_type.replace(/_/g, ' ')}</td>
      <td>{data.host}</td>
    </tr>
  )
}
