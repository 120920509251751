import { TGroupedData } from './interfaces'

export const groupBy = (objectArray: Record<string, any>, property: string): TGroupedData => {
  return objectArray.reduce((acc: TGroupedData, obj: Record<string, any>) => {
    const key = obj[property]
    if (!acc[key]) acc[key] = []

    acc[key].push(obj.count)
    return acc
  }, {})
}

const calculatePoint = (
  index: number,
  intervalSize: number,
  colorRangeInfo: { colorStart: number; colorEnd: number; useEndAsStart: boolean }
): number => {
  const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo
  return useEndAsStart ? colorEnd - index * intervalSize : colorStart + index * intervalSize
}

export const interpolateColors = (
  dataLength: number,
  colorScale: (value: number) => string,
  colorRangeInfo: { colorStart: number; colorEnd: number; useEndAsStart: boolean }
): string[] => {
  const { colorStart, colorEnd } = colorRangeInfo
  const colorRange = colorEnd - colorStart
  const intervalSize = colorRange / dataLength
  let i
  let colorPoint
  const colorArray = []

  // eslint-disable-next-line no-plusplus
  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo)
    colorArray.push(colorScale(colorPoint))
  }

  return colorArray
}
