import { ITheme } from './Lib/interfaces'

export const retro8086: ITheme = {
  colors: {
    'main-background-color': 'rgba(13,2,34,1)',
    'secondary-background-color': 'rgba(38,20,72,1)',
    'offset-background-color': 'rgba(100,87,199,1)',
    'main-font-color': 'rgba(240,245,255,1)',
    'main-border-color': 'rgba(239,133,237,1)',

    'secondary-background-font-color': 'rgba(45,227,230,1)',

    'primary-contrast-color': 'rgba(8,122,253,1)',
    'secondary-contrast-color': 'rgba(101,13,135,1)',

    'alert-color': 'rgba(255,56,99,1)',
    'warning-color': 'rgba(249,198,16,1)',
    'success-color': 'rgba(101,185,70,1)',
  },
  properties: {
    'background-image': 'url(/backgrounds/retro8086.jpg)',
    'login-banner-image': 'url(/login_banner_images/cyberus.png)',
  },
}
