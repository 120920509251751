import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Panel: React.FC<IProps> = ({ title, banner, showLoginBanner, children }): JSX.Element => {
  return (
    <div className={Styles.panel}>
      <div className={Styles.title}>{title}</div>
      {banner && <img alt="banner" src={banner} width={600} />}
      {showLoginBanner && <div className={Styles.loginBanner} />}
      <div className={Styles.content}>{children}</div>
    </div>
  )
}
