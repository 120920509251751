type TFilterArgs = Array<Array<string>>

export const filterBuilder = (filters: TFilterArgs): unknown => {
  const filter = {}
  if (!filters.length) return filter
  const parts: Record<string, string | Record<string, string>>[] = []
  filters.forEach(([key, value]) => {
    if (key === 'notifications' && value === 'true') {
      parts.push({
        _or: [
          // @ts-ignore
          { ipInfo: { _or: [{ is_malicious: { _eq: true } }, { is_abusive: { _eq: true } }] } },
          // @ts-ignore
          { notifications: { id: { _is_null: false } } },
        ],
      })
    } else parts.push({ [key]: { _eq: value }, _and: {} as Record<string, string> })
  })

  if (parts.length > 1) {
    parts.forEach((part, index) => {
      const right = parts.length - index
      const left = right - 1
      // @ts-ignore
      parts[left]._and = parts[right]
    })
  }

  console.log('filter parts', parts[0])
  return parts[0]
}
