import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

import { GetEventDocument, INotifications, useGetIpInformationQuery } from '../../../../Lib/graphql'
import { GoogleMap } from '../../../GoogleMap'
import { IModalProps, THistory, TNotification } from '../../interfaces'
import TableStyles from '../../styles.module.scss'
import { TableHeader } from '../../tableHeader'
import { EventInfo } from '../EventInfo'
import { IpReputation } from '../IpReputation'
import { WhoisResponse } from '../WhoisResponse'
import Styles from './styles.module.scss'

const renderNotificationRow = (notification: TNotification): JSX.Element => {
  return (
    <tr>
      <td>{dayjs(notification.created_at).format('DD-MM-YY HH:mm:ss')}</td>
      <td>{notification.rule_triggered.replace(/_/g, ' ')}</td>
      <td>{notification.comment}</td>
      <td>{notification.severity_level}</td>
    </tr>
  )
}

export const Modal: React.FC<IModalProps> = ({
  eventLog,
  onRequestClose,
  isOpen,
  setModalData,
}): JSX.Element | null => {
  const { t } = useTranslation()
  const { data, loading } = useGetIpInformationQuery({ variables: { ip: eventLog?.client_ip } })
  const apolloClient = useApolloClient()
  if (!eventLog) return null

  const renderNotifications = (
    notifications: Array<
      { __typename?: 'notifications' } & Pick<
        INotifications,
        'comment' | 'created_at' | 'event_log_id' | 'id' | 'marked_as_read_at' | 'rule_triggered' | 'severity_level'
      >
    >
  ): JSX.Element | null => {
    if (notifications === undefined || notifications.length === 0) return null

    return (
      <table className={TableStyles.table}>
        <thead>
          <TableHeader items={['reported at', 'rule triggered', 'comment', 'severity_level']} />
        </thead>
        <tbody>{notifications.map(renderNotificationRow)}</tbody>
      </table>
    )
  }

  const renderGoogleMap = (): JSX.Element | null => {
    if (!data?.ip_information_by_pk?.longitude || !data?.ip_information_by_pk?.latitude) return null
    return (
      <div className={Styles.mapContainer}>
        <div className={Styles.mapHeader}>{t('Location')}</div>
        <GoogleMap
          longitude={data.ip_information_by_pk.longitude}
          latitude={data.ip_information_by_pk.latitude}
          zoomLevel={8}
        />
        <div className={Styles.coordinates}>
          <span>{data.ip_information_by_pk.latitude}</span>
          <span>{data.ip_information_by_pk.longitude}</span>
        </div>
      </div>
    )
  }
  const fetchEvent = (row: THistory): void => {
    apolloClient
      .query({ query: GetEventDocument, variables: { id: row.id } })
      .then((newData) => {
        setModalData(newData.data.event_logs_by_pk)
      })
      .catch((err) => {
        alert(`could not fetch event because ${err.message}`)
      })
  }

  const renderHistoryRow = (row: THistory): JSX.Element => {
    return (
      <tr onClick={(): void => fetchEvent(row)} className={eventLog.id === row.id ? Styles.historySelected : ''}>
        <td>{row.reported_at}</td>
        <td>{row.event_type}</td>
      </tr>
    )
  }

  const renderHistory = (): JSX.Element | null => {
    if (!eventLog.history?.length) return null

    return (
      <table className={TableStyles.table}>
        <caption>{t('Last 10 known events')}</caption>
        <thead>
          <TableHeader items={['reported at', 'event type']} />
        </thead>
        <tbody>{eventLog.history.map(renderHistoryRow)}</tbody>
      </table>
    )
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
    >
      <div className={Styles.close} onClick={onRequestClose}>
        X
      </div>
      <h1>{t('Event Details for {{ ip }}', { ip: eventLog.client_ip })}</h1>
      <div className={Styles.infoBlocks}>
        {/* @ts-ignore */}
        <EventInfo eventLog={eventLog} />
        <WhoisResponse data={data} />
        <IpReputation data={data} />
      </div>
      <div className={Styles.notificationContainer}>{renderNotifications(eventLog?.notifications)}</div>
      <div className={Styles.googleMapsContainer}>{renderGoogleMap()}</div>
      <div className={Styles.historyContainer}>{renderHistory()}</div>
    </ReactModal>
  )
}
