import React, { useEffect, useMemo, useState } from 'react'

import { business } from '../../Themes/business'
import { ITheme } from '../../Themes/Lib/interfaces'
import { retro8086 } from '../../Themes/retro8086'
import { setCSSColorVarsForColors, setCSSColorVarsForProperties } from './helpers'
import { IThemeContext, TThemeNames } from './interfaces'

const themes = { retro8086, business }

export const ThemeContext = React.createContext<IThemeContext>({
  currentTheme: business,
  currentThemeName: 'business',
  setCurrentThemeName: (_theme: TThemeNames) => null,
})

export const ThemeProvider: React.FC<{ children: React.ReactNode; theme?: TThemeNames }> = ({
  children,
  theme,
}): JSX.Element | null => {
  const initialTheme = theme || 'retro8086'

  const [currentThemeName, setCurrentThemeName] = useState<TThemeNames>(initialTheme)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [themeColor, setThemeColor] = useState<ITheme>(business)

  // Set initial fallback
  useEffect(() => {
    setCSSColorVarsForColors(themeColor.colors)
    setCSSColorVarsForProperties(themeColor.properties)
  }, [themeColor])

  // This useEffect hook below processes the CSS when we received a response from the backend
  useEffect(() => {
    if (!initialized) setInitialized(true)

    setThemeColor(themes[currentThemeName])
  }, [initialized])

  useEffect(() => {
    setThemeColor(themes[currentThemeName])
  }, [currentThemeName])

  const options = useMemo(() => {
    return { currentTheme: themeColor || {}, currentThemeName, setCurrentThemeName }
  }, [currentThemeName, themeColor])

  if (!initialized) return null

  return <ThemeContext.Provider value={options}>{children}</ThemeContext.Provider>
}
