import { useAuth0 } from '@auth0/auth0-react'
import React, { FormEvent, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ThemeContext } from '../../Providers/ThemeProvider'
import { TThemeNames } from '../../Providers/ThemeProvider/interfaces'
import { Button } from '../Button'
import Styles from './styles.module.scss'

export const Navigation: React.FC = (): JSX.Element => {
  const { user, logout } = useAuth0()
  const { setCurrentThemeName } = useContext(ThemeContext)
  const { t } = useTranslation()

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    // @ts-ignore
    const query = document.getElementById('search-input')?.value
    location.hash = `ip=${query}`
  }

  return (
    <div className={Styles.topBar}>
      <div className={Styles.title}>{t('Cerberus')}</div>

      <form onSubmit={handleSubmit}>
        <input id="search-input" type="text" placeholder={t('Search ip...')} className={Styles.input} />
      </form>
      <div className={Styles.rightPart}>
        <select
          onChange={(event): void => {
            setCurrentThemeName(event.target.value as TThemeNames)
          }}
        >
          <option value="retro8086">Retro8086</option>
          <option value="business">InfoSec</option>
        </select>
        <div className={Styles.avatar}>
          <img src={user?.picture} />
        </div>
        <Button onClick={(): void => logout()} type="primary" compact>
          {t('Logout')}
        </Button>
      </div>
    </div>
  )
}
