import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'

import { filterBuilder } from '../../Lib/filter_builder'
import { useGetEventsQuery, useGetEventsTableDataSubscription } from '../../Lib/graphql'
import { FrostedGlassContainer } from '../FrostedGlassContainer'
import { Icon } from '../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'
import { Table } from './table'

export const EventsTable: React.FC<IProps> = ({ setSelectedEventType }): JSX.Element => {
  const [filters, setFilters] = useState<Record<string, string | null>>({
    client_ip: null,
    environment: null,
    event: null,
    host: null,
    notifications: null,
  })
  const { t } = useTranslation()
  const [maxResults, setMaxResults] = useState<number>(20)
  const [where, setWhere] = useState({})

  const [pageCount, setPageCount] = useState(0)
  const [useLiveQuery, setUseLiveQuery] = useState<boolean>(true)
  const [filterFlags, setFilterFlags] = useState<boolean>(false)

  const { loading, data, refetch } = useGetEventsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { where, limit: maxResults },
  })

  const { loading: liveLoading, data: liveData } = useGetEventsTableDataSubscription({
    variables: { where, limit: maxResults },
  })

  const filterIpEventListener = () => {
    const ip = window.location.hash.replace('#ip=', '')
    if (
      ip === '' ||
      ip.match(
        /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gm
      )
    ) {
      const newFilters = { ...filters }
      newFilters.client_ip = ip === '' ? null : ip
      setFilters(newFilters)
    } else alert(t('You need to supply a full ipv4 or ipv6 address. {{ip}} is not valid.', { ip }))
  }

  useEffect(() => {
    window.addEventListener('hashchange', filterIpEventListener)
    return (): void => {
      window.removeEventListener('hashchange', filterIpEventListener)
    }
  }, [filterIpEventListener, filters, t])

  useEffect(() => {
    const mappedFilters = Object.keys(filters)
      .map((key) => {
        if (filters[key] === ('' || null)) return null
        return [key, filters[key]]
      })
      .filter((value) => value !== null)

    // @ts-ignore no idea how to fix this signature
    const filterArgs = filterBuilder(mappedFilters)

    // @ts-ignore no idea how to fix this signature
    setWhere({ ...filterArgs })
  }, [filters])

  useEffect(() => {
    if (!data?.event_logs_aggregate?.aggregate?.count) return
    setPageCount(Math.ceil(data.event_logs_aggregate.aggregate?.count / maxResults))
  }, [data, maxResults])

  const handleSelect =
    (filter: string) =>
    (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = event.target.value === '' ? null : event.target.value
      const newFilters = { ...filters }
      newFilters[filter] = value
      setFilters(newFilters)
      if (filter === 'event_type') setSelectedEventType(value)
    }

  const handleFlagFilter = (checked: boolean): void => {
    const newFilters = { ...filters }
    newFilters.notifications = checked ? true.toString() : null
    setFilterFlags(checked)
    setFilters(newFilters)
  }

  const handlePageSelect = (selectedItem: { selected: number }): void => {
    refetch({ offset: selectedItem.selected * maxResults })
  }
  const toggleLive = (): void => {
    const flipped = !useLiveQuery
    if (!flipped) refetch({ offset: 0 })
    setUseLiveQuery(!useLiveQuery)
  }

  return (
    <React.Fragment>
      <div className={Styles.topBar}>
        <Icon
          name={useLiveQuery ? 'sat_on' : 'sat_off'}
          height={32}
          width={32}
          className={useLiveQuery ? Styles.live : Styles.static}
          onClick={toggleLive}
        />
      </div>
      <FrostedGlassContainer>
        <Table
          maxResults={maxResults}
          onMaxResultChange={setMaxResults}
          tableData={useLiveQuery ? liveData?.event_logs : data?.event_logs}
          handleSelect={handleSelect}
          handleFlagFilter={handleFlagFilter}
          filters={filters}
          loading={loading}
          filterFlags={filterFlags}
        />
      </FrostedGlassContainer>

      {!useLiveQuery && (
        <div className={Styles.paginationContainer}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageSelect}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={(): null => null}
            containerClassName="paginator"
          />
        </div>
      )}
    </React.Fragment>
  )
}
