import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from '../Locales/en/translation.json'
import nl from '../Locales/nl/translation.json'

export const SUPPORTED_LOCALES = ['en', 'nl']

const resources = {
  en: {
    translation: en,
    string: 'English',
  },
  nl: {
    translation: nl,
    string: 'Nederlands',
  },
}

i18n
  // The languageDetector plugin makes sure to check `localStorage` first, if that does not contain a language setting
  // it falls back on the browsers default language. When the user changes the language it automatically stores this in
  // `localStorage`. See its repo for more info: https://github.com/i18next/i18next-browser-languageDetector.
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    initImmediate: true,
    resources,
    supportedLngs: SUPPORTED_LOCALES,
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        platform: process.env.REACT_APP_PLATFORM || 'Reisbalans',
      },
    },
  })
export default i18n
