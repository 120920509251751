import React from 'react'
import Skeleton from 'react-loading-skeleton'

import Styles from './styles.module.scss'

export const skeletonRow = (amount: number): JSX.Element[] => {
  return [...Array(amount).keys()].map((_, index) => {
    return (
      <tr key={`skeleton-${index}`}>
        <td className={Styles.flag}>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    )
  })
}
