/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  inet: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
}


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface IBooleanComparisonExp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IIntComparisonExp {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface IStringComparisonExp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface IBigintComparisonExp {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface IDateComparisonExp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}

export enum IEnvironmentsEnum {
  /** A development environment */
  DEVELOPMENT = 'development',
  /** A production environment */
  PRODUCTION = 'production',
  /** A staging environment */
  STAGING = 'staging',
  /** A test environment */
  TEST = 'test'
}

/** Boolean expression to compare columns of type "environments_enum". All fields are combined with logical 'AND'. */
export interface IEnvironmentsEnumComparisonExp {
  _eq?: Maybe<IEnvironmentsEnum>;
  _in?: Maybe<Array<IEnvironmentsEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IEnvironmentsEnum>;
  _nin?: Maybe<Array<IEnvironmentsEnum>>;
}

/** columns and relationships of "event_logs" */
export interface IEventLogs {
  __typename?: 'event_logs';
  additional_data?: Maybe<Scalars['jsonb']>;
  client_ip?: Maybe<Scalars['inet']>;
  environment: IEnvironmentsEnum;
  event_type: IEventTypesEnum;
  /** An array relationship */
  history: Array<IEventLogs>;
  /** An aggregate relationship */
  history_aggregate: IEventLogsAggregate;
  host?: Maybe<Scalars['String']>;
  /** An object relationship */
  host_alias?: Maybe<IHostAliases>;
  id: Scalars['uuid'];
  /** An object relationship */
  ipInfo?: Maybe<IIpInformation>;
  /** fetch data from the table: "notifications" */
  notifications: Array<INotifications>;
  reported_at: Scalars['timestamptz'];
  reported_by: Scalars['String'];
}


/** columns and relationships of "event_logs" */
export interface IEventLogsAdditionalDataArgs {
  path?: Maybe<Scalars['String']>;
}


/** columns and relationships of "event_logs" */
export interface IEventLogsHistoryArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


/** columns and relationships of "event_logs" */
export interface IEventLogsHistoryAggregateArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


/** columns and relationships of "event_logs" */
export interface IEventLogsNotificationsArgs {
  distinct_on?: Maybe<Array<INotificationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INotificationsOrderBy>>;
  where?: Maybe<INotificationsBoolExp>;
}

/** aggregated selection of "event_logs" */
export interface IEventLogsAggregate {
  __typename?: 'event_logs_aggregate';
  aggregate?: Maybe<IEventLogsAggregateFields>;
  nodes: Array<IEventLogs>;
}

/** aggregate fields of "event_logs" */
export interface IEventLogsAggregateFields {
  __typename?: 'event_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IEventLogsMaxFields>;
  min?: Maybe<IEventLogsMinFields>;
}


/** aggregate fields of "event_logs" */
export interface IEventLogsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IEventLogsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "event_logs" */
export interface IEventLogsAggregateOrderBy {
  count?: Maybe<IOrderBy>;
  max?: Maybe<IEventLogsMaxOrderBy>;
  min?: Maybe<IEventLogsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "event_logs". All fields are combined with a logical 'AND'. */
export interface IEventLogsBoolExp {
  _and?: Maybe<Array<IEventLogsBoolExp>>;
  _not?: Maybe<IEventLogsBoolExp>;
  _or?: Maybe<Array<IEventLogsBoolExp>>;
  additional_data?: Maybe<IJsonbComparisonExp>;
  client_ip?: Maybe<IInetComparisonExp>;
  environment?: Maybe<IEnvironmentsEnumComparisonExp>;
  event_type?: Maybe<IEventTypesEnumComparisonExp>;
  history?: Maybe<IEventLogsBoolExp>;
  host?: Maybe<IStringComparisonExp>;
  host_alias?: Maybe<IHostAliasesBoolExp>;
  id?: Maybe<IUuidComparisonExp>;
  ipInfo?: Maybe<IIpInformationBoolExp>;
  notifications?: Maybe<INotificationsBoolExp>;
  reported_at?: Maybe<ITimestamptzComparisonExp>;
  reported_by?: Maybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IEventLogsMaxFields {
  __typename?: 'event_logs_max_fields';
  host?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reported_at?: Maybe<Scalars['timestamptz']>;
  reported_by?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "event_logs" */
export interface IEventLogsMaxOrderBy {
  host?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  reported_at?: Maybe<IOrderBy>;
  reported_by?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IEventLogsMinFields {
  __typename?: 'event_logs_min_fields';
  host?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reported_at?: Maybe<Scalars['timestamptz']>;
  reported_by?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "event_logs" */
export interface IEventLogsMinOrderBy {
  host?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  reported_at?: Maybe<IOrderBy>;
  reported_by?: Maybe<IOrderBy>;
}

/** Ordering options when selecting data from "event_logs". */
export interface IEventLogsOrderBy {
  additional_data?: Maybe<IOrderBy>;
  client_ip?: Maybe<IOrderBy>;
  environment?: Maybe<IOrderBy>;
  event_type?: Maybe<IOrderBy>;
  history_aggregate?: Maybe<IEventLogsAggregateOrderBy>;
  host?: Maybe<IOrderBy>;
  host_alias?: Maybe<IHostAliasesOrderBy>;
  id?: Maybe<IOrderBy>;
  ipInfo?: Maybe<IIpInformationOrderBy>;
  notifications_aggregate?: Maybe<INotificationsAggregateOrderBy>;
  reported_at?: Maybe<IOrderBy>;
  reported_by?: Maybe<IOrderBy>;
}

/** select columns of table "event_logs" */
export enum IEventLogsSelectColumn {
  /** column name */
  ADDITIONAL_DATA = 'additional_data',
  /** column name */
  CLIENT_IP = 'client_ip',
  /** column name */
  ENVIRONMENT = 'environment',
  /** column name */
  EVENT_TYPE = 'event_type',
  /** column name */
  HOST = 'host',
  /** column name */
  ID = 'id',
  /** column name */
  REPORTED_AT = 'reported_at',
  /** column name */
  REPORTED_BY = 'reported_by'
}

/** columns and relationships of "event_type_stats" */
export interface IEventTypeStats {
  __typename?: 'event_type_stats';
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
  event_type?: Maybe<Scalars['String']>;
}

/** Boolean expression to filter rows from the table "event_type_stats". All fields are combined with a logical 'AND'. */
export interface IEventTypeStatsBoolExp {
  _and?: Maybe<Array<IEventTypeStatsBoolExp>>;
  _not?: Maybe<IEventTypeStatsBoolExp>;
  _or?: Maybe<Array<IEventTypeStatsBoolExp>>;
  count?: Maybe<IBigintComparisonExp>;
  date?: Maybe<IDateComparisonExp>;
  event_type?: Maybe<IStringComparisonExp>;
}

/** Ordering options when selecting data from "event_type_stats". */
export interface IEventTypeStatsOrderBy {
  count?: Maybe<IOrderBy>;
  date?: Maybe<IOrderBy>;
  event_type?: Maybe<IOrderBy>;
}

/** select columns of table "event_type_stats" */
export enum IEventTypeStatsSelectColumn {
  /** column name */
  COUNT = 'count',
  /** column name */
  DATE = 'date',
  /** column name */
  EVENT_TYPE = 'event_type'
}

/** columns and relationships of "event_types" */
export interface IEventTypes {
  __typename?: 'event_types';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** Boolean expression to filter rows from the table "event_types". All fields are combined with a logical 'AND'. */
export interface IEventTypesBoolExp {
  _and?: Maybe<Array<IEventTypesBoolExp>>;
  _not?: Maybe<IEventTypesBoolExp>;
  _or?: Maybe<Array<IEventTypesBoolExp>>;
  description?: Maybe<IStringComparisonExp>;
  value?: Maybe<IStringComparisonExp>;
}

export enum IEventTypesEnum {
  /** An admin user has been activated */
  ADMIN_ACTIVATED = 'admin_activated',
  /** An admin user assumed a specific employee as user */
  ADMIN_ASSUMED_EMPLOYEE = 'admin_assumed_employee',
  /** An admin user has been created */
  ADMIN_CREATED = 'admin_created',
  /** An admin user has logged in */
  ADMIN_LOGIN = 'admin_login',
  /** An admin user failed to login */
  ADMIN_LOGIN_FAILED = 'admin_login_failed',
  /** An admin tried to login with the wrong credentials multiple times and has only one attempt left */
  ADMIN_LOGIN_FAILED_LAST_ATTEMPT = 'admin_login_failed_last_attempt',
  /** An admin tried to login with the wrong credentials multiple times and has now been temporarily been locked */
  ADMIN_LOGIN_FAILED_LOCKED = 'admin_login_failed_locked',
  /** An admin tried to login with an email address that we do not recognise */
  ADMIN_LOGIN_FAILED_UNKNOWN_ACCOUNT = 'admin_login_failed_unknown_account',
  /** An admin user has logged out */
  ADMIN_LOGOUT = 'admin_logout',
  /** An admin user has reset their password */
  ADMIN_PASSWORD_RESET = 'admin_password_reset',
  /** An employee has been activated */
  EMPLOYEE_ACTIVATED = 'employee_activated',
  /** An employee has been added to a budget group */
  EMPLOYEE_ADDED_TO_BUDGET_GROUP = 'employee_added_to_budget_group',
  /** An employee has been created */
  EMPLOYEE_CREATED = 'employee_created',
  /** An employee has been deactivated */
  EMPLOYEE_DEACTIVATED = 'employee_deactivated',
  /** An employee has logged in */
  EMPLOYEE_LOGIN = 'employee_login',
  /** An employee failed to login */
  EMPLOYEE_LOGIN_FAILED = 'employee_login_failed',
  /** An employee tried to login with the wrong credentials multiple times and has only one attempt left */
  EMPLOYEE_LOGIN_FAILED_LAST_ATTEMPT = 'employee_login_failed_last_attempt',
  /** An employee tried to login with the wrong credentials multiple times and has now been temporarily been locked */
  EMPLOYEE_LOGIN_FAILED_LOCKED = 'employee_login_failed_locked',
  /** An employee tried to login with an email address that we do not recognise */
  EMPLOYEE_LOGIN_FAILED_UNKNOWN_ACCOUNT = 'employee_login_failed_unknown_account',
  /** An employee has logged out */
  EMPLOYEE_LOGOUT = 'employee_logout',
  /** An employee has requested a password reset */
  EMPLOYEE_PASSWORD_RESET = 'employee_password_reset',
  /** An employee has been removed from a budget group */
  EMPLOYEE_REMOVED_FROM_BUDGET_GROUP = 'employee_removed_from_budget_group',
  /** The rate limiter has blocked a certain ip as it's trying to brute force something */
  RATE_LIMITER_BLOCKED_IP = 'rate_limiter_blocked_ip',
  /** A user logged in through ssh */
  SSH_LOGIN = 'ssh_login'
}

/** Boolean expression to compare columns of type "event_types_enum". All fields are combined with logical 'AND'. */
export interface IEventTypesEnumComparisonExp {
  _eq?: Maybe<IEventTypesEnum>;
  _in?: Maybe<Array<IEventTypesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IEventTypesEnum>;
  _nin?: Maybe<Array<IEventTypesEnum>>;
}

/** Ordering options when selecting data from "event_types". */
export interface IEventTypesOrderBy {
  description?: Maybe<IOrderBy>;
  value?: Maybe<IOrderBy>;
}

/** select columns of table "event_types" */
export enum IEventTypesSelectColumn {
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  VALUE = 'value'
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface IFloat8ComparisonExp {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
}

/** columns and relationships of "host_aliases" */
export interface IHostAliases {
  __typename?: 'host_aliases';
  alias: Scalars['String'];
  hostname: Scalars['String'];
}

/** Boolean expression to filter rows from the table "host_aliases". All fields are combined with a logical 'AND'. */
export interface IHostAliasesBoolExp {
  _and?: Maybe<Array<IHostAliasesBoolExp>>;
  _not?: Maybe<IHostAliasesBoolExp>;
  _or?: Maybe<Array<IHostAliasesBoolExp>>;
  alias?: Maybe<IStringComparisonExp>;
  hostname?: Maybe<IStringComparisonExp>;
}

/** Ordering options when selecting data from "host_aliases". */
export interface IHostAliasesOrderBy {
  alias?: Maybe<IOrderBy>;
  hostname?: Maybe<IOrderBy>;
}

/** select columns of table "host_aliases" */
export enum IHostAliasesSelectColumn {
  /** column name */
  ALIAS = 'alias',
  /** column name */
  HOSTNAME = 'hostname'
}


/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export interface IInetComparisonExp {
  _eq?: Maybe<Scalars['inet']>;
  _gt?: Maybe<Scalars['inet']>;
  _gte?: Maybe<Scalars['inet']>;
  _in?: Maybe<Array<Scalars['inet']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['inet']>;
  _lte?: Maybe<Scalars['inet']>;
  _neq?: Maybe<Scalars['inet']>;
  _nin?: Maybe<Array<Scalars['inet']>>;
}

/** columns and relationships of "ip_information" */
export interface IIpInformation {
  __typename?: 'ip_information';
  city: Scalars['String'];
  country_code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  domain: Scalars['String'];
  flag_image: Scalars['String'];
  ip: Scalars['inet'];
  is_abusive: Scalars['Boolean'];
  is_malicious: Scalars['Boolean'];
  isp: Scalars['String'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  organization: Scalars['String'];
  risk: Scalars['Int'];
  risk_level: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  whois_contact_email?: Maybe<Scalars['String']>;
  whois_country_code?: Maybe<Scalars['String']>;
  whois_name?: Maybe<Scalars['String']>;
  whois_organization?: Maybe<Scalars['String']>;
}

/** Boolean expression to filter rows from the table "ip_information". All fields are combined with a logical 'AND'. */
export interface IIpInformationBoolExp {
  _and?: Maybe<Array<IIpInformationBoolExp>>;
  _not?: Maybe<IIpInformationBoolExp>;
  _or?: Maybe<Array<IIpInformationBoolExp>>;
  city?: Maybe<IStringComparisonExp>;
  country_code?: Maybe<IStringComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  domain?: Maybe<IStringComparisonExp>;
  flag_image?: Maybe<IStringComparisonExp>;
  ip?: Maybe<IInetComparisonExp>;
  is_abusive?: Maybe<IBooleanComparisonExp>;
  is_malicious?: Maybe<IBooleanComparisonExp>;
  isp?: Maybe<IStringComparisonExp>;
  latitude?: Maybe<IFloat8ComparisonExp>;
  longitude?: Maybe<IFloat8ComparisonExp>;
  organization?: Maybe<IStringComparisonExp>;
  risk?: Maybe<IIntComparisonExp>;
  risk_level?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  whois_contact_email?: Maybe<IStringComparisonExp>;
  whois_country_code?: Maybe<IStringComparisonExp>;
  whois_name?: Maybe<IStringComparisonExp>;
  whois_organization?: Maybe<IStringComparisonExp>;
}

/** Ordering options when selecting data from "ip_information". */
export interface IIpInformationOrderBy {
  city?: Maybe<IOrderBy>;
  country_code?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  domain?: Maybe<IOrderBy>;
  flag_image?: Maybe<IOrderBy>;
  ip?: Maybe<IOrderBy>;
  is_abusive?: Maybe<IOrderBy>;
  is_malicious?: Maybe<IOrderBy>;
  isp?: Maybe<IOrderBy>;
  latitude?: Maybe<IOrderBy>;
  longitude?: Maybe<IOrderBy>;
  organization?: Maybe<IOrderBy>;
  risk?: Maybe<IOrderBy>;
  risk_level?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  whois_contact_email?: Maybe<IOrderBy>;
  whois_country_code?: Maybe<IOrderBy>;
  whois_name?: Maybe<IOrderBy>;
  whois_organization?: Maybe<IOrderBy>;
}

/** select columns of table "ip_information" */
export enum IIpInformationSelectColumn {
  /** column name */
  CITY = 'city',
  /** column name */
  COUNTRY_CODE = 'country_code',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DOMAIN = 'domain',
  /** column name */
  FLAG_IMAGE = 'flag_image',
  /** column name */
  IP = 'ip',
  /** column name */
  IS_ABUSIVE = 'is_abusive',
  /** column name */
  IS_MALICIOUS = 'is_malicious',
  /** column name */
  ISP = 'isp',
  /** column name */
  LATITUDE = 'latitude',
  /** column name */
  LONGITUDE = 'longitude',
  /** column name */
  ORGANIZATION = 'organization',
  /** column name */
  RISK = 'risk',
  /** column name */
  RISK_LEVEL = 'risk_level',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WHOIS_CONTACT_EMAIL = 'whois_contact_email',
  /** column name */
  WHOIS_COUNTRY_CODE = 'whois_country_code',
  /** column name */
  WHOIS_NAME = 'whois_name',
  /** column name */
  WHOIS_ORGANIZATION = 'whois_organization'
}


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface IJsonbComparisonExp {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
}

/** mutation root */
export interface IMutationRoot {
  __typename?: 'mutation_root';
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<INotificationsMutationResponse>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<INotifications>;
}


/** mutation root */
export interface IMutationRootUpdateNotificationsArgs {
  _set?: Maybe<INotificationsSetInput>;
  where: INotificationsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNotificationsByPkArgs {
  _set?: Maybe<INotificationsSetInput>;
  pk_columns: INotificationsPkColumnsInput;
}

/** columns and relationships of "notifications" */
export interface INotifications {
  __typename?: 'notifications';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  event_log_id: Scalars['uuid'];
  id: Scalars['uuid'];
  marked_as_read_at?: Maybe<Scalars['timestamptz']>;
  rule_triggered: IRuleTypesEnum;
  severity_level: ISeverityLevelsEnum;
}

/** order by aggregate values of table "notifications" */
export interface INotificationsAggregateOrderBy {
  count?: Maybe<IOrderBy>;
  max?: Maybe<INotificationsMaxOrderBy>;
  min?: Maybe<INotificationsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export interface INotificationsBoolExp {
  _and?: Maybe<Array<INotificationsBoolExp>>;
  _not?: Maybe<INotificationsBoolExp>;
  _or?: Maybe<Array<INotificationsBoolExp>>;
  comment?: Maybe<IStringComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  event_log_id?: Maybe<IUuidComparisonExp>;
  id?: Maybe<IUuidComparisonExp>;
  marked_as_read_at?: Maybe<ITimestamptzComparisonExp>;
  rule_triggered?: Maybe<IRuleTypesEnumComparisonExp>;
  severity_level?: Maybe<ISeverityLevelsEnumComparisonExp>;
}

/** order by max() on columns of table "notifications" */
export interface INotificationsMaxOrderBy {
  comment?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  event_log_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  marked_as_read_at?: Maybe<IOrderBy>;
}

/** order by min() on columns of table "notifications" */
export interface INotificationsMinOrderBy {
  comment?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  event_log_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  marked_as_read_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "notifications" */
export interface INotificationsMutationResponse {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INotifications>;
}

/** Ordering options when selecting data from "notifications". */
export interface INotificationsOrderBy {
  comment?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  event_log_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  marked_as_read_at?: Maybe<IOrderBy>;
  rule_triggered?: Maybe<IOrderBy>;
  severity_level?: Maybe<IOrderBy>;
}

/** primary key columns input for table: notifications */
export interface INotificationsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "notifications" */
export enum INotificationsSelectColumn {
  /** column name */
  COMMENT = 'comment',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EVENT_LOG_ID = 'event_log_id',
  /** column name */
  ID = 'id',
  /** column name */
  MARKED_AS_READ_AT = 'marked_as_read_at',
  /** column name */
  RULE_TRIGGERED = 'rule_triggered',
  /** column name */
  SEVERITY_LEVEL = 'severity_level'
}

/** input type for updating data in table "notifications" */
export interface INotificationsSetInput {
  marked_as_read_at?: Maybe<Scalars['timestamptz']>;
}

/** column ordering options */
export enum IOrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

export interface IQueryRoot {
  __typename?: 'query_root';
  /** fetch data from the table: "event_logs" */
  event_logs: Array<IEventLogs>;
  /** fetch aggregated fields from the table: "event_logs" */
  event_logs_aggregate: IEventLogsAggregate;
  /** fetch data from the table: "event_logs" using primary key columns */
  event_logs_by_pk?: Maybe<IEventLogs>;
  /** fetch data from the table: "event_type_stats" */
  event_type_stats: Array<IEventTypeStats>;
  /** fetch data from the table: "event_types" */
  event_types: Array<IEventTypes>;
  /** fetch data from the table: "event_types" using primary key columns */
  event_types_by_pk?: Maybe<IEventTypes>;
  /** fetch data from the table: "host_aliases" */
  host_aliases: Array<IHostAliases>;
  /** fetch data from the table: "host_aliases" using primary key columns */
  host_aliases_by_pk?: Maybe<IHostAliases>;
  /** fetch data from the table: "ip_information" */
  ip_information: Array<IIpInformation>;
  /** fetch data from the table: "ip_information" using primary key columns */
  ip_information_by_pk?: Maybe<IIpInformation>;
  /** fetch data from the table: "notifications" */
  notifications: Array<INotifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<INotifications>;
  /** fetch data from the table: "rule_types" */
  rule_types: Array<IRuleTypes>;
  /** fetch data from the table: "rule_types" using primary key columns */
  rule_types_by_pk?: Maybe<IRuleTypes>;
  /** fetch data from the table: "severity_levels" */
  severity_levels: Array<ISeverityLevels>;
  /** fetch data from the table: "severity_levels" using primary key columns */
  severity_levels_by_pk?: Maybe<ISeverityLevels>;
}


export interface IQueryRootEventLogsArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


export interface IQueryRootEventLogsAggregateArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


export interface IQueryRootEventLogsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootEventTypeStatsArgs {
  distinct_on?: Maybe<Array<IEventTypeStatsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventTypeStatsOrderBy>>;
  where?: Maybe<IEventTypeStatsBoolExp>;
}


export interface IQueryRootEventTypesArgs {
  distinct_on?: Maybe<Array<IEventTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventTypesOrderBy>>;
  where?: Maybe<IEventTypesBoolExp>;
}


export interface IQueryRootEventTypesByPkArgs {
  value: Scalars['String'];
}


export interface IQueryRootHostAliasesArgs {
  distinct_on?: Maybe<Array<IHostAliasesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHostAliasesOrderBy>>;
  where?: Maybe<IHostAliasesBoolExp>;
}


export interface IQueryRootHostAliasesByPkArgs {
  hostname: Scalars['String'];
}


export interface IQueryRootIpInformationArgs {
  distinct_on?: Maybe<Array<IIpInformationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IIpInformationOrderBy>>;
  where?: Maybe<IIpInformationBoolExp>;
}


export interface IQueryRootIpInformationByPkArgs {
  ip: Scalars['inet'];
}


export interface IQueryRootNotificationsArgs {
  distinct_on?: Maybe<Array<INotificationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INotificationsOrderBy>>;
  where?: Maybe<INotificationsBoolExp>;
}


export interface IQueryRootNotificationsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootRuleTypesArgs {
  distinct_on?: Maybe<Array<IRuleTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRuleTypesOrderBy>>;
  where?: Maybe<IRuleTypesBoolExp>;
}


export interface IQueryRootRuleTypesByPkArgs {
  value: Scalars['String'];
}


export interface IQueryRootSeverityLevelsArgs {
  distinct_on?: Maybe<Array<ISeverityLevelsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ISeverityLevelsOrderBy>>;
  where?: Maybe<ISeverityLevelsBoolExp>;
}


export interface IQueryRootSeverityLevelsByPkArgs {
  value: Scalars['String'];
}

/** columns and relationships of "rule_types" */
export interface IRuleTypes {
  __typename?: 'rule_types';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** Boolean expression to filter rows from the table "rule_types". All fields are combined with a logical 'AND'. */
export interface IRuleTypesBoolExp {
  _and?: Maybe<Array<IRuleTypesBoolExp>>;
  _not?: Maybe<IRuleTypesBoolExp>;
  _or?: Maybe<Array<IRuleTypesBoolExp>>;
  description?: Maybe<IStringComparisonExp>;
  value?: Maybe<IStringComparisonExp>;
}

export enum IRuleTypesEnum {
  /** Someone logged in from different ip's within a certain timeframe */
  LOGGED_IN_FROM_DIFFERENT_IPS_WITHIN_TIMEFRAME = 'logged_in_from_different_ips_within_timeframe',
  /** This event has been registered multiple times from the same ip within a certain timeframe */
  MULTIPLE_OCCURRENCES_FROM_SAME_IP_WITHIN_TIMEFRAME = 'multiple_occurrences_from_same_ip_within_timeframe',
  /** Something happend outside business hours */
  OUTSIDE_BUSINESS_HOURS = 'outside_business_hours'
}

/** Boolean expression to compare columns of type "rule_types_enum". All fields are combined with logical 'AND'. */
export interface IRuleTypesEnumComparisonExp {
  _eq?: Maybe<IRuleTypesEnum>;
  _in?: Maybe<Array<IRuleTypesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IRuleTypesEnum>;
  _nin?: Maybe<Array<IRuleTypesEnum>>;
}

/** Ordering options when selecting data from "rule_types". */
export interface IRuleTypesOrderBy {
  description?: Maybe<IOrderBy>;
  value?: Maybe<IOrderBy>;
}

/** select columns of table "rule_types" */
export enum IRuleTypesSelectColumn {
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  VALUE = 'value'
}

/** columns and relationships of "severity_levels" */
export interface ISeverityLevels {
  __typename?: 'severity_levels';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** Boolean expression to filter rows from the table "severity_levels". All fields are combined with a logical 'AND'. */
export interface ISeverityLevelsBoolExp {
  _and?: Maybe<Array<ISeverityLevelsBoolExp>>;
  _not?: Maybe<ISeverityLevelsBoolExp>;
  _or?: Maybe<Array<ISeverityLevelsBoolExp>>;
  description?: Maybe<IStringComparisonExp>;
  value?: Maybe<IStringComparisonExp>;
}

export enum ISeverityLevelsEnum {
  /** A critical incident */
  CRITICAL = 'critical',
  /** A high incident */
  HIGH = 'high',
  /** Just information data */
  INFORMATIONAL = 'informational',
  /** A minor incident */
  LOW = 'low',
  /** A medium incident */
  MEDIUM = 'medium'
}

/** Boolean expression to compare columns of type "severity_levels_enum". All fields are combined with logical 'AND'. */
export interface ISeverityLevelsEnumComparisonExp {
  _eq?: Maybe<ISeverityLevelsEnum>;
  _in?: Maybe<Array<ISeverityLevelsEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ISeverityLevelsEnum>;
  _nin?: Maybe<Array<ISeverityLevelsEnum>>;
}

/** Ordering options when selecting data from "severity_levels". */
export interface ISeverityLevelsOrderBy {
  description?: Maybe<IOrderBy>;
  value?: Maybe<IOrderBy>;
}

/** select columns of table "severity_levels" */
export enum ISeverityLevelsSelectColumn {
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  VALUE = 'value'
}

export interface ISubscriptionRoot {
  __typename?: 'subscription_root';
  /** fetch data from the table: "event_logs" */
  event_logs: Array<IEventLogs>;
  /** fetch aggregated fields from the table: "event_logs" */
  event_logs_aggregate: IEventLogsAggregate;
  /** fetch data from the table: "event_logs" using primary key columns */
  event_logs_by_pk?: Maybe<IEventLogs>;
  /** fetch data from the table: "event_type_stats" */
  event_type_stats: Array<IEventTypeStats>;
  /** fetch data from the table: "event_types" */
  event_types: Array<IEventTypes>;
  /** fetch data from the table: "event_types" using primary key columns */
  event_types_by_pk?: Maybe<IEventTypes>;
  /** fetch data from the table: "host_aliases" */
  host_aliases: Array<IHostAliases>;
  /** fetch data from the table: "host_aliases" using primary key columns */
  host_aliases_by_pk?: Maybe<IHostAliases>;
  /** fetch data from the table: "ip_information" */
  ip_information: Array<IIpInformation>;
  /** fetch data from the table: "ip_information" using primary key columns */
  ip_information_by_pk?: Maybe<IIpInformation>;
  /** fetch data from the table: "notifications" */
  notifications: Array<INotifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<INotifications>;
  /** fetch data from the table: "rule_types" */
  rule_types: Array<IRuleTypes>;
  /** fetch data from the table: "rule_types" using primary key columns */
  rule_types_by_pk?: Maybe<IRuleTypes>;
  /** fetch data from the table: "severity_levels" */
  severity_levels: Array<ISeverityLevels>;
  /** fetch data from the table: "severity_levels" using primary key columns */
  severity_levels_by_pk?: Maybe<ISeverityLevels>;
}


export interface ISubscriptionRootEventLogsArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


export interface ISubscriptionRootEventLogsAggregateArgs {
  distinct_on?: Maybe<Array<IEventLogsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventLogsOrderBy>>;
  where?: Maybe<IEventLogsBoolExp>;
}


export interface ISubscriptionRootEventLogsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootEventTypeStatsArgs {
  distinct_on?: Maybe<Array<IEventTypeStatsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventTypeStatsOrderBy>>;
  where?: Maybe<IEventTypeStatsBoolExp>;
}


export interface ISubscriptionRootEventTypesArgs {
  distinct_on?: Maybe<Array<IEventTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventTypesOrderBy>>;
  where?: Maybe<IEventTypesBoolExp>;
}


export interface ISubscriptionRootEventTypesByPkArgs {
  value: Scalars['String'];
}


export interface ISubscriptionRootHostAliasesArgs {
  distinct_on?: Maybe<Array<IHostAliasesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IHostAliasesOrderBy>>;
  where?: Maybe<IHostAliasesBoolExp>;
}


export interface ISubscriptionRootHostAliasesByPkArgs {
  hostname: Scalars['String'];
}


export interface ISubscriptionRootIpInformationArgs {
  distinct_on?: Maybe<Array<IIpInformationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IIpInformationOrderBy>>;
  where?: Maybe<IIpInformationBoolExp>;
}


export interface ISubscriptionRootIpInformationByPkArgs {
  ip: Scalars['inet'];
}


export interface ISubscriptionRootNotificationsArgs {
  distinct_on?: Maybe<Array<INotificationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INotificationsOrderBy>>;
  where?: Maybe<INotificationsBoolExp>;
}


export interface ISubscriptionRootNotificationsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootRuleTypesArgs {
  distinct_on?: Maybe<Array<IRuleTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRuleTypesOrderBy>>;
  where?: Maybe<IRuleTypesBoolExp>;
}


export interface ISubscriptionRootRuleTypesByPkArgs {
  value: Scalars['String'];
}


export interface ISubscriptionRootSeverityLevelsArgs {
  distinct_on?: Maybe<Array<ISeverityLevelsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ISeverityLevelsOrderBy>>;
  where?: Maybe<ISeverityLevelsBoolExp>;
}


export interface ISubscriptionRootSeverityLevelsByPkArgs {
  value: Scalars['String'];
}


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface ITimestamptzComparisonExp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface IUuidComparisonExp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

export type IGetEventQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type IGetEventQuery = (
  { __typename?: 'query_root' }
  & { event_logs_by_pk?: Maybe<(
    { __typename?: 'event_logs' }
    & IEventDataFragment
  )> }
);

export type IGetIpInformationQueryVariables = Exact<{
  ip: Scalars['inet'];
}>;


export type IGetIpInformationQuery = (
  { __typename?: 'query_root' }
  & { ip_information_by_pk?: Maybe<(
    { __typename?: 'ip_information' }
    & Pick<IIpInformation, 'city' | 'country_code' | 'created_at' | 'domain' | 'flag_image' | 'ip' | 'is_abusive' | 'is_malicious' | 'isp' | 'latitude' | 'longitude' | 'organization' | 'risk' | 'risk_level' | 'updated_at' | 'whois_contact_email' | 'whois_country_code' | 'whois_organization' | 'whois_name'>
  )> }
);

export type IEventDataFragment = (
  { __typename?: 'event_logs' }
  & Pick<IEventLogs, 'client_ip' | 'environment' | 'event_type' | 'host' | 'id' | 'reported_at' | 'reported_by' | 'additional_data'>
  & { host_alias?: Maybe<(
    { __typename?: 'host_aliases' }
    & Pick<IHostAliases, 'alias'>
  )>, ipInfo?: Maybe<(
    { __typename?: 'ip_information' }
    & Pick<IIpInformation, 'city' | 'flag_image' | 'is_abusive' | 'is_malicious'>
  )>, notifications: Array<(
    { __typename?: 'notifications' }
    & Pick<INotifications, 'comment' | 'created_at' | 'event_log_id' | 'id' | 'marked_as_read_at' | 'rule_triggered' | 'severity_level'>
  )>, history: Array<(
    { __typename?: 'event_logs' }
    & Pick<IEventLogs, 'id' | 'event_type' | 'reported_at'>
  )> }
);

export type IGetEventsQueryVariables = Exact<{
  where?: Maybe<IEventLogsBoolExp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IGetEventsQuery = (
  { __typename?: 'query_root' }
  & { event_logs: Array<(
    { __typename?: 'event_logs' }
    & IEventDataFragment
  )>, event_logs_aggregate: (
    { __typename?: 'event_logs_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'event_logs_aggregate_fields' }
      & Pick<IEventLogsAggregateFields, 'count'>
    )> }
  ) }
);

export type IGetEventsTableDataSubscriptionVariables = Exact<{
  where?: Maybe<IEventLogsBoolExp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type IGetEventsTableDataSubscription = (
  { __typename?: 'subscription_root' }
  & { event_logs: Array<(
    { __typename?: 'event_logs' }
    & IEventDataFragment
  )> }
);

export type IGetEventTypeStatsQueryVariables = Exact<{
  where?: Maybe<IEventTypeStatsBoolExp>;
}>;


export type IGetEventTypeStatsQuery = (
  { __typename?: 'query_root' }
  & { event_type_stats: Array<(
    { __typename?: 'event_type_stats' }
    & Pick<IEventTypeStats, 'date' | 'event_type' | 'count'>
  )> }
);

export const EventDataFragmentDoc = gql`
    fragment eventData on event_logs {
  client_ip
  environment
  event_type
  host
  id
  reported_at
  reported_by
  additional_data
  host_alias {
    alias
  }
  ipInfo {
    city
    flag_image
    is_abusive
    is_malicious
  }
  notifications {
    comment
    created_at
    event_log_id
    id
    marked_as_read_at
    rule_triggered
    severity_level
  }
  history(limit: 10, order_by: {reported_at: desc}) {
    id
    event_type
    reported_at
  }
}
    `;
export const GetEventDocument = gql`
    query getEvent($id: uuid!) {
  event_logs_by_pk(id: $id) {
    ...eventData
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<IGetEventQuery, IGetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventQuery, IGetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventQuery, IGetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventQuery, IGetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<IGetEventQuery, IGetEventQueryVariables>;
export const GetIpInformationDocument = gql`
    query GetIpInformation($ip: inet!) {
  ip_information_by_pk(ip: $ip) {
    city
    country_code
    created_at
    domain
    flag_image
    ip
    is_abusive
    is_malicious
    isp
    latitude
    longitude
    organization
    risk
    risk_level
    updated_at
    whois_contact_email
    whois_country_code
    whois_organization
    whois_name
  }
}
    `;

/**
 * __useGetIpInformationQuery__
 *
 * To run a query within a React component, call `useGetIpInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpInformationQuery({
 *   variables: {
 *      ip: // value for 'ip'
 *   },
 * });
 */
export function useGetIpInformationQuery(baseOptions: Apollo.QueryHookOptions<IGetIpInformationQuery, IGetIpInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetIpInformationQuery, IGetIpInformationQueryVariables>(GetIpInformationDocument, options);
      }
export function useGetIpInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetIpInformationQuery, IGetIpInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetIpInformationQuery, IGetIpInformationQueryVariables>(GetIpInformationDocument, options);
        }
export type GetIpInformationQueryHookResult = ReturnType<typeof useGetIpInformationQuery>;
export type GetIpInformationLazyQueryHookResult = ReturnType<typeof useGetIpInformationLazyQuery>;
export type GetIpInformationQueryResult = Apollo.QueryResult<IGetIpInformationQuery, IGetIpInformationQueryVariables>;
export const GetEventsDocument = gql`
    query getEvents($where: event_logs_bool_exp = {}, $limit: Int = 20, $offset: Int = 1) {
  event_logs(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {reported_at: desc}
  ) {
    ...eventData
  }
  event_logs_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<IGetEventsQuery, IGetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventsQuery, IGetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventsQuery, IGetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventsQuery, IGetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<IGetEventsQuery, IGetEventsQueryVariables>;
export const GetEventsTableDataDocument = gql`
    subscription getEventsTableData($where: event_logs_bool_exp = {}, $limit: Int = 20) {
  event_logs(where: $where, limit: $limit, order_by: {reported_at: desc}) {
    ...eventData
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useGetEventsTableDataSubscription__
 *
 * To run a query within a React component, call `useGetEventsTableDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsTableDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsTableDataSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEventsTableDataSubscription(baseOptions?: Apollo.SubscriptionHookOptions<IGetEventsTableDataSubscription, IGetEventsTableDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IGetEventsTableDataSubscription, IGetEventsTableDataSubscriptionVariables>(GetEventsTableDataDocument, options);
      }
export type GetEventsTableDataSubscriptionHookResult = ReturnType<typeof useGetEventsTableDataSubscription>;
export type GetEventsTableDataSubscriptionResult = Apollo.SubscriptionResult<IGetEventsTableDataSubscription>;
export const GetEventTypeStatsDocument = gql`
    query getEventTypeStats($where: event_type_stats_bool_exp = {}) {
  event_type_stats(where: $where) {
    date
    event_type
    count
  }
}
    `;

/**
 * __useGetEventTypeStatsQuery__
 *
 * To run a query within a React component, call `useGetEventTypeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTypeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTypeStatsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEventTypeStatsQuery(baseOptions?: Apollo.QueryHookOptions<IGetEventTypeStatsQuery, IGetEventTypeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventTypeStatsQuery, IGetEventTypeStatsQueryVariables>(GetEventTypeStatsDocument, options);
      }
export function useGetEventTypeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventTypeStatsQuery, IGetEventTypeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventTypeStatsQuery, IGetEventTypeStatsQueryVariables>(GetEventTypeStatsDocument, options);
        }
export type GetEventTypeStatsQueryHookResult = ReturnType<typeof useGetEventTypeStatsQuery>;
export type GetEventTypeStatsLazyQueryHookResult = ReturnType<typeof useGetEventTypeStatsLazyQuery>;
export type GetEventTypeStatsQueryResult = Apollo.QueryResult<IGetEventTypeStatsQuery, IGetEventTypeStatsQueryVariables>;