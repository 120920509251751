import React from 'react'

import { ITableHeaderProps } from './interfaces'

export const TableHeader: React.FC<ITableHeaderProps> = ({ items }): JSX.Element => {
  return (
    <tr key={`row-${new Date()}`}>
      {items.map((item, index) => (
        <th key={`item-${index}`}>{item}</th>
      ))}
    </tr>
  )
}
