import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import TableStyles from '../../styles.module.scss'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EventInfo = ({ eventLog }: IProps): JSX.Element | null => {
  const { t } = useTranslation()

  useEffect(() => {
    const elements = document.getElementsByClassName('prettyprinted') as unknown as HTMLElement[]

    if (elements && elements.length) elements[0].className = 'prettyprint'

    // @ts-ignore
    window.PR.prettyPrint()
  }, [eventLog])

  if (!eventLog) return null

  const renderRow = (label: string, value: any): JSX.Element => {
    return (
      <tr>
        <td>{label}</td>
        <td>{value?.toString()}</td>
      </tr>
    )
  }

  return (
    <div>
      <table className={TableStyles.table}>
        <caption>{t('Event Information')}</caption>
        <tbody>
          {renderRow('Environment', eventLog.environment)}
          {renderRow('Event', eventLog.event_type.replace(/_/g, ' '))}
          {renderRow('Host', eventLog.host)}
          {eventLog.host_alias?.alias && renderRow('Alias', eventLog.host_alias.alias)}
          {renderRow('Reported At', dayjs(eventLog.reported_at).format('DD-MM-YY HH:mm:ss'))}
          {renderRow('Reported By', eventLog.reported_by)}
        </tbody>
      </table>
      <div className={Styles.header}>{t('Additional Data')}</div>
      <pre className="prettyprint" lang-js style={{ margin: 0 }}>
        {JSON.stringify(eventLog.additional_data, null, 2)}
      </pre>
    </div>
  )
}
