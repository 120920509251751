import { ITheme } from './Lib/interfaces'

export const business: ITheme = {
  colors: {
    'main-background-color': 'rgba(5,40,36,1)',
    'secondary-background-color': 'rgba(16,68,105,1)',
    'offset-background-color': 'rgba(81,165,159,1)',
    'main-font-color': 'rgba(238,244,255,1)',
    'main-border-color': 'rgba(3,118,127,1)',

    'secondary-background-font-color': 'rgba(0,0,0,1)',

    'primary-contrast-color': 'rgba(8,120,252,1)',
    'secondary-contrast-color': 'rgba(172,255,91,1)',

    'alert-color': 'rgba(255,57,100,1)',
    'warning-color': 'rgba(249,200,14,1)',
    'success-color': 'rgba(102,186,71,1)',
  },
  properties: {
    'background-image': 'url(/backgrounds/business.jpg)',
    'login-banner-image': 'url(/login_banner_images/cyberus.png)',
  },
}
