import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  BubbleDataPoint,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartTypeRegistry,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  ScatterDataPoint,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import { interpolateRainbow } from 'd3'
import { RefObject, useRef } from 'react'

import { interpolateColors } from './helpers'
import { TGroupedData } from './interfaces'

export const useChart = (): {
  setChartData: (
    chartContainerRef: RefObject<HTMLCanvasElement> | null,
    title: string,
    labels: string[],
    groupedData: TGroupedData
  ) => void
} => {
  const chart = useRef<Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[]>>()

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  )

  const setChartData = (
    chartContainerRef: RefObject<HTMLCanvasElement> | null,
    title: string,
    labels: string[],
    groupedData: TGroupedData
  ): void => {
    const colorRangeInfo = {
      colorStart: 0,
      colorEnd: 1,
      useEndAsStart: false,
    }

    const colors = interpolateColors(Object.keys(groupedData).length, interpolateRainbow, colorRangeInfo)

    const datasets = Object.keys(groupedData).map((key, index) => {
      return {
        data: groupedData[key],
        label: key,
        fill: false,
        borderColor: colors[index],
        backgroundColor: colors[index],
      }
    })

    const chartOptions: ChartConfiguration = {
      type: 'bar',
      data: {
        datasets,
        labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: 'rgb(255, 99, 132)',
            },
          },
        },
      },
    }
    // @ts-ignore
    const ctx = document.getElementById('chart')?.getContext('2d')

    if (chart.current) chart.current.destroy()
    chart.current = new Chart(ctx, chartOptions)
  }

  return { setChartData }
}
