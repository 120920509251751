import { IHSLA, IRGBA, IThemeColors, IThemeProperties } from '../../Themes/Lib/interfaces'
import {
  convertHexToRGBA,
  darkenColor,
  getContrastColor,
  HSLAObjectToHSLAString,
  lightenColor,
} from '../../Themes/Lib/utils'
import { ICSSColorVarsMap, IVariantMap } from './interfaces'

const getColorKeys = Object.keys as <TColorsFromTheme>(colorsObj: TColorsFromTheme) => (keyof TColorsFromTheme)[]

const opacityMap = [20, 40, 60, 80]

const variantMap: IVariantMap = {
  contrast: (_hsla, rgba) => convertHexToRGBA(getContrastColor(rgba), rgba.alpha),
  dark: (hsla) => darkenColor(hsla),
  default: (hsla) => HSLAObjectToHSLAString(hsla),
  light: (hsla) => lightenColor(hsla),
  hex: (hsla) => HSLAToHex(hsla),
}

const HSLAToHex = (hsla: IHSLA): string => {
  // eslint-disable-next-line no-param-reassign
  hsla.lightness /= 100
  const a = (hsla.saturation * Math.min(hsla.lightness, 1 - hsla.lightness)) / 100
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@getify/proper-arrows/params
  // @ts-ignore
  const f = (n) => {
    const k = (n + hsla.hue / 30) % 12
    const color = hsla.lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0')
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

const RGBAStringTORGBAHash = (rgba: string): IRGBA => {
  const [red, green, blue, alpha] = rgba
    .replace(/^rgba\(/, '')
    .replace(/\)$/, '')
    .split(',')

  return {
    red: parseInt(red, 10),
    green: parseInt(green, 10),
    blue: parseInt(blue, 10),
    alpha: parseInt(alpha, 10),
  }
}

const HSLAStringTOHSLAHash = (rgba: string): IHSLA => {
  const [hue, saturation, lightness, alpha] = rgba
    .replace(/^hsla\(/, '')
    .replace(/\)$/, '')
    .split(',')

  return {
    hue: parseInt(hue, 10),
    saturation: parseInt(saturation, 10),
    lightness: parseInt(lightness, 10),
    alpha: parseInt(alpha, 10),
  }
}

export const generateCSSColorVars = (colorName: string, hsla: IHSLA, rgba: IRGBA): ICSSColorVarsMap => {
  return Object.keys(variantMap).reduce((accumulator: ICSSColorVarsMap, variantName) => {
    const colorGeneratorFn = variantMap[variantName]

    // If the variant is called 'default', only the color name will be used as variantName.
    const cssVarName = variantName !== 'default' ? `--${colorName}-${variantName}` : `--${colorName}`

    // Create default variant first
    accumulator[cssVarName] = colorGeneratorFn(hsla, rgba)
    opacityMap.forEach((opacity) => {
      // Now create and add various opacities
      accumulator[`${cssVarName}-opacity-${opacity}`] = colorGeneratorFn(
        { ...hsla, alpha: opacity / 100 },
        { ...rgba, alpha: opacity / 100 }
      )
    })

    return accumulator
  }, {})
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RGBAToHSLA = (rgbaString: string): string => {
  const ex =
    /^rgba\(((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i
  if (ex.test(rgbaString)) {
    const sep = rgbaString.indexOf(',') > -1 ? ',' : ' '
    const rgba = rgbaString.substr(5).split(')')[0].split(sep)

    // strip the slash if using space-separated syntax
    if (rgba.indexOf('/') > -1) rgba.splice(3, 1)

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const R in rgba) {
      const r = rgba[R]
      if (r.indexOf('%') > -1) {
        const p = parseInt(r.substr(0, r.length - 1), 10) / 100

        // @ts-ignore
        if (R < 3) rgba[R] = Math.round(p * 255).toString()
      }
    }

    // make r, g, and b fractions of 1
    // @ts-ignore
    const r = rgba[0] / 255

    // @ts-ignore
    const g = rgba[1] / 255

    // @ts-ignore
    const b = rgba[2] / 255

    const a = rgba[3]
    // find greatest and smallest channel values
    const cmin = Math.min(r, g, b)
    const cmax = Math.max(r, g, b)
    const delta = cmax - cmin
    let h = 0
    let s = 0
    let l = 0

    // calculate hue
    // no difference
    if (delta === 0) h = 0
    // red is max
    else if (cmax === r) h = ((g - b) / delta) % 6
    // green is max
    else if (cmax === g) h = (b - r) / delta + 2
    // blue is max
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    // make negative hues positive behind 360°
    if (h < 0) h += 360

    // calculate lightness
    l = (cmax + cmin) / 2

    // calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    // multiply l and s by 100
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return `hsla(${h},${s}%,${l}%,${a})`
  }
  return 'Invalid input color'
}

export const setCSSColorVarsForColors = (colors: IThemeColors): void =>
  getColorKeys(colors).forEach((colorKey) => {
    if (!colors[colorKey]) return

    const rgbaString = colors[colorKey]
    const rgbaHash: IRGBA = RGBAStringTORGBAHash(rgbaString)
    const hslaHash: IHSLA = HSLAStringTOHSLAHash(RGBAToHSLA(rgbaString))

    const cssVarsMap = generateCSSColorVars(colorKey as string, hslaHash, rgbaHash)
    Object.keys(cssVarsMap).forEach((cssVarName) => {
      document.documentElement.style.setProperty(cssVarName, cssVarsMap[cssVarName])
    })
  })

export const setCSSColorVarsForProperties = (properties: IThemeProperties): void => {
  Object.keys(properties).forEach((prop) => {
    // @ts-ignore
    document.documentElement.style.setProperty(`--${prop}`, properties[prop])
  })
}
