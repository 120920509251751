// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import { IHSLA, IRGBA } from './interfaces'

export const HSLAObjectToHSLAString = (color: IHSLA): string =>
  `hsla(${color.hue}, ${color.saturation}%, ${color.lightness}%, ${color.alpha})`

export const lightenColor = (color: IHSLA, lightnessIncrement = 20): string =>
  HSLAObjectToHSLAString({
    hue: color.hue,
    saturation: color.saturation,
    lightness: color.lightness + lightnessIncrement,
    alpha: color.alpha,
  })

export const darkenColor = (color: IHSLA, lightnessDecrement = 20): string =>
  HSLAObjectToHSLAString({
    hue: color.hue,
    saturation: color.saturation,
    lightness: color.lightness - lightnessDecrement,
    alpha: color.alpha,
  })

export const convertHexToRGBA = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) hex += hex

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export const convertHexToIRGBA = (hexCode: string): IRGBA => {
  if (!hexCode) return { red: 0, green: 0, blue: 0, alpha: 0 }
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) hex += hex
  return {
    red: parseInt(hex.substring(0, 2), 16),
    green: parseInt(hex.substring(2, 4), 16),
    blue: parseInt(hex.substring(4, 6), 16),
    alpha: 0,
  }
}

export const getContrastColor = (rgba: IRGBA, contrast = 0.65): string => {
  const yiq = (rgba.red * 299 + rgba.green * 587 + rgba.blue * 114) / 1000
  return yiq >= 256 * contrast ? '#000' : '#fff'
}

export const wrappedPublicUrl = (path: string): string => {
  return `url(${process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : ''}${path})`
}
