import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EventsTable } from '../../Components/EventsTable'
import { Navigation } from '../../Components/Navigation'
import { EventTypeStats } from '../../Components/StatsTypeStats'
import { apolloClient } from '../../Lib/apolloClient'
import Styles from './styles.module.scss'

export const Dashboard: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0<{ name: string }>()
  const [selectedEventType, setSelectedEventType] = useState<string | null>(null)
  const [jwt, setJwt] = useState<string>()

  useEffect(() => {
    ;(async () => {
      const claims = await getIdTokenClaims()
      setJwt(claims.__raw)
    })()
  }, [getAccessTokenSilently, getIdTokenClaims])

  if (!jwt) return <div>{t('Loading...')}</div>

  return (
    <ApolloProvider client={apolloClient(jwt)}>
      <div className={Styles.pageContainer}>
        <Navigation />
        <EventsTable setSelectedEventType={setSelectedEventType} />
        <EventTypeStats selectedEventType={selectedEventType} />
      </div>
    </ApolloProvider>
  )
}

export default Dashboard
