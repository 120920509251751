import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../Components/Button'
import { Panel } from '../../Components/Panel'
import Styles from './styles.module.scss'

export const Login: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  const { loginWithRedirect } = useAuth0<{ name: string }>()

  return (
    <div className={Styles.loginContainer}>
      <Panel title={t(`Welcome to Cerberus`)} showLoginBanner>
        <Button type="primary" onClick={loginWithRedirect}>
          {t('Log in')}
        </Button>
      </Panel>
    </div>
  )
}
