import React from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useChart } from '../../Hooks/useChart'
import { groupBy } from '../../Hooks/useChart/helpers'
import { useGetEventTypeStatsQuery } from '../../Lib/graphql'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EventTypeStats: React.FC<IProps> = ({ selectedEventType }): JSX.Element => {
  const where = selectedEventType ? { event_type: { _eq: selectedEventType } } : {}

  const { data, loading } = useGetEventTypeStatsQuery({
    variables: { where },
    pollInterval: 60000,
  })

  const { t } = useTranslation()
  const { setChartData } = useChart()
  const chartContainerRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (loading || !data) return

    const labels = [...new Set(data.event_type_stats.map((entry) => entry.date))]
    const grouped = groupBy(data.event_type_stats, 'event_type')

    setChartData(chartContainerRef, 'title here', labels, grouped)
  }, [data, loading, setChartData, t])

  return (
    <div>
      <canvas ref={chartContainerRef} className={Styles.chart} id="chart" />
    </div>
  )
}
