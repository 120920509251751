import './Lib/i18n'

import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

import Dashboard from './Pages/Dashboard'
import { Login } from './Pages/Login'
import { ThemeProvider } from './Providers/ThemeProvider'
import Styles from './styles.module.scss'

export const App: React.FC = (): JSX.Element | null => {
  const { isLoading, isAuthenticated, error } = useAuth0<{ name: string }>()

  if (isLoading) return null

  if (error) return <div>Oops... {error.message}</div>

  const loginOrMain = (): JSX.Element => {
    if (isAuthenticated) return <Dashboard />

    return <Login />
  }

  return (
    <ThemeProvider>
      <div className={Styles.mainContainer}>{loginOrMain()}</div>
    </ThemeProvider>
  )
}
