import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Button: React.FC<IProps> = ({ type, compact, onClick, children }): JSX.Element => {
  const getClassName = (): string => {
    switch (type) {
      case 'secondary':
        return Styles.secondary
      case 'ghost':
        return Styles.ghost
      default:
        return Styles.primary
    }
  }

  const cssClasses = [Styles.button, getClassName()]

  if (compact) cssClasses.push(Styles.compact)

  return (
    // eslint-disable-next-line react/button-has-type,reisbalans-rules/prevent-vanilla-html-elements
    <button onClick={onClick} className={cssClasses.join(' ')}>
      {children}
    </button>
  )
}
