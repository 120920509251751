import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Pill = ({ type, children }: IProps): JSX.Element => {
  const getClass = (): string => {
    switch (type) {
      case 'low':
        return Styles.info
      case 'medium':
        return Styles.warning
      case 'high':
        return Styles.alert
      case 'alert':
        return Styles.alert
      case 'warning':
        return Styles.warning
      default:
        return Styles.info
    }
  }

  const classNames = [Styles.pill, getClass()]
  return <div className={classNames.join(' ')}>{children}</div>
}
