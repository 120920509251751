import React, { useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useGoogleMaps } from '../../Hooks/useGoogleMaps'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const GoogleMap: React.FC<IProps> = ({
  longitude = 4.955688900000041,
  latitude = 52.356449,
  zoomLevel = 14,
  onClick,
  className,
}): JSX.Element | null => {
  const mapContainerRef = useRef<HTMLDivElement>(null)

  const { setZoomLevel } = useGoogleMaps({
    mapContainerRef,
    longitude,
    latitude,
    zoomLevel,
    onClick,
  })

  useEffect(() => {
    setZoomLevel(zoomLevel)
  }, [zoomLevel, setZoomLevel])

  return (
    <div ref={mapContainerRef} className={[Styles.googleMaps, className].join(' ')}>
      <Skeleton className="googleMapsSkeleton" />
    </div>
  )
}
