/**
 * This helper will ensure that the Google Maps API has been loaded.
 * First it checks if window.google.maps is already present, if so it just runs the callback.
 * Otherwise, it will start injecting it and wait for it to load, once loaded it calls the callback.
 * If any other components are initializing, and an injection is already happening, it will wait for that one to finish and then call the callback.
 *
 * OMG You are using globals!!11!
 * I know, but this is to prevent multiple injections happening at the same time when more then 1 map component is shown on a page.
 *
 * @param callback - A function to call once the Google Maps API has finished loading.
 */

// FIXME This should not be exposed, how to handle this? /smeevil

export const injectGoogleMapsJs = (callback: () => void): void => {
  // There has been a call or process before that injected maps and its already available, just run the callback
  if (window.google?.maps) {
    callback()
    return
  }

  const handleCallback = (): void => {
    callback()
  }

  if (window.googleMapsInjecting) {
    // We are in the process of injecting, setup an interval to see when its done and call the callback
    const interval = setInterval((): void => {
      if (window.google?.maps) {
        clearInterval(interval)
        callback()
      }
    }, 100)
    return
  }

  // Nothing has happend yet, so lets kick of the actual injection.
  window.googleMapsInjecting = true
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}`
  script.async = true
  script.onload = (): void => handleCallback()

  document.body.appendChild(script)
}
