import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { enumValues } from '../../Lib/enumKeys'
import { IEnvironmentsEnum, IEventTypesEnum } from '../../Lib/graphql'
import { Modal } from './Components/Modal'
import { ITableProps, TEventLog } from './interfaces'
import { Row } from './row'
import { skeletonRow } from './skeletonRow'
import Styles from './styles.module.scss'
import { TableHeader } from './tableHeader'

export const Table: React.FC<ITableProps> = ({
  tableData,
  handleSelect,
  handleFlagFilter,
  loading = false,
  filters,
  maxResults = 20,
  onMaxResultChange,
  filterFlags,
}): JSX.Element => {
  const [modalData, setModalData] = useState<TEventLog | null>(null)
  const { t } = useTranslation()

  const renderFilter = (
    label: string,
    property: string,
    iterator?: typeof IEventTypesEnum | typeof IEnvironmentsEnum
  ): JSX.Element | string => {
    if (!tableData) return label
    const values = iterator
      ? enumValues(iterator).map((value) => value)
      : [...new Set(tableData.map((log: Record<string, any>) => log[property]))]
    return (
      <select className="select" onChange={handleSelect(property)} defaultValue={filters[property]}>
        <option disabled>{label}</option>
        <option value="">{t('No filter')}</option>
        {values?.sort().map((value) => (
          <option key={`filter-${property}-${value}`}>{value}</option>
        ))}
      </select>
    )
  }

  return (
    <div>
      <table className={Styles.table}>
        <thead>
          <TableHeader
            items={[
              <input
                type="checkbox"
                onChange={(event): void => handleFlagFilter(event.target.checked)}
                checked={filterFlags}
              />,
              'Reported at',
              renderFilter(t('Client Ip'), 'client_ip'),
              renderFilter(t('Environment'), 'environment', IEnvironmentsEnum),
              renderFilter(t('Event Type'), 'event_type', IEventTypesEnum),
              renderFilter(t('Host'), 'host'),
            ]}
          />
        </thead>
        <tbody>
          {!loading && tableData
            ? tableData.map((data, index) => (
                <Row key={`row-${index}`} data={data} flag onClick={(): void => setModalData(data)} />
              ))
            : skeletonRow(maxResults || 10)}
        </tbody>
        {onMaxResultChange && (
          <tfoot>
            <tr>
              <th colSpan={5}>{t('Results per page')}</th>
              <th style={{ textAlign: 'right' }}>
                <select
                  defaultValue={maxResults}
                  onChange={(event): void => onMaxResultChange(parseInt(event.target.value, 10))}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </th>
            </tr>
          </tfoot>
        )}
      </table>
      <Modal
        setModalData={setModalData}
        eventLog={modalData}
        onRequestClose={(): void => setModalData(null)}
        isOpen={modalData !== null}
      />
    </div>
  )
}
