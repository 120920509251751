export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => (Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]);

export const enumValues = <O extends object, V extends O = O>(obj: O): O[Extract<keyof O, string> ][] => {
    const values = new Array<O[Extract<keyof O, string>]>()
    for (const value in obj) {
        values.push(obj[value])
    }
    return values
}

