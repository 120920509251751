import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import { iconAliases, isIconAlias } from './aliases'
import { IProps, TIconState } from './interface'

/* When you encounter an error like: 'IIconEnum cant match TIconName | TIconAliases' it is caused by a mismatch between icons that the backend returns vs icons we have available.
   You can properly debug this by casting IIconEnum to a string literal type with :
   type IIconEnumValues = `${IIconEnum}`
*/

export const Icon: React.FC<IProps> = ({ height = 16, name, width = 16, ...svgProps }): JSX.Element | null => {
  const [IconComponent, setIconComponent] = useState<TIconState>(null)

  const alias = isIconAlias(name) ? iconAliases[name] : name

  useEffect(() => {
    // Load SVG icons eagerly (i.e. in one chunk together with all other code on this page)
    // The @svgr/webpack loader is added as a fix, see this comment:
    // https://github.com/facebook/create-react-app/issues/5276#issuecomment-665628393
    // This has been moved into chunkMaker.ts so that webpack sees this as a separate import and creates a stand alone pack.
    import(
      /* webpackChunkName: "SvgIconsPack" */
      /* webpackPreload: true */
      `./chunkMaker`
    )
      .then((getIcon) => getIcon.default(alias))
      .then((icon) => {
        setIconComponent(icon.default)
      })
      .catch((err) => console.error('icon loader ran into a problem: ', err))
  }, [alias])

  if (!IconComponent) return <Skeleton width={width} height={height} />

  return <IconComponent height={height} width={width} {...svgProps} />
}
