import { TIconName } from './iconNames'

export type TIconAliases = 'live' | 'static'

export const iconAliases: { [alias in TIconAliases]: TIconName } = {
  live: 'sat_on',
  static: 'sat_off',
}

export const isIconAlias = (name: string): name is TIconAliases => {
  return Object.keys(iconAliases).includes(name)
}
