import React from 'react'
import { useTranslation } from 'react-i18next'

import { IGetIpInformationQuery } from '../../../../Lib/graphql'
import Styles from '../../styles.module.scss'
import { Pill } from '../Pill'
import { TRisk } from '../Pill/interfaces'

export const IpReputation = ({ data }: { data?: IGetIpInformationQuery }): JSX.Element | null => {
  const { t } = useTranslation()
  if (!data?.ip_information_by_pk) return null

  const renderRow = (label: string, value: any): JSX.Element => {
    return (
      <tr>
        <td>{label}</td>
        <td>{value?.toString()}</td>
      </tr>
    )
  }

  return (
    <table className={Styles.table}>
      <caption>{t('Ip Reputation')}</caption>
      <tbody>
        <tr>
          <td>{t('RiskLevel')}</td>
          <td>
            <Pill type={data.ip_information_by_pk.risk_level as TRisk}>
              {data.ip_information_by_pk.risk_level} ({data.ip_information_by_pk.risk})
            </Pill>
          </td>
        </tr>
        <tr>
          <td>{t('Country')}</td>
          <td>
            <img src={data.ip_information_by_pk.flag_image} height={12} alt="flag" />{' '}
            {data.ip_information_by_pk.country_code} - {data.ip_information_by_pk.city}
          </td>
        </tr>
        {renderRow('organization', data.ip_information_by_pk.organization)}
        {renderRow('domain', data.ip_information_by_pk.domain)}
        {renderRow('isp', data.ip_information_by_pk.isp)}
        <tr>
          <td>{t('is malicious')}</td>
          <td>
            <Pill type={data.ip_information_by_pk.is_malicious ? 'alert' : 'info'}>
              {data.ip_information_by_pk.is_malicious.toString()}
            </Pill>
          </td>
        </tr>

        <tr>
          <td>{t('is abusive')}</td>
          <td>
            <Pill type={data.ip_information_by_pk.is_abusive ? 'alert' : 'info'}>
              {data.ip_information_by_pk.is_abusive.toString()}
            </Pill>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
